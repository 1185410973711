import { Button, CircularProgress, Grid, Typography } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import { EVENT } from "@talsho/types";
import { useState } from "react";
import { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import Alert from "../../../../components/alert";
import firebaseApp from "../../../../firebase";
import Routes from "../../../../utils/routes";
import EventForm from "../../../../components/eventForm";
import styles from "./styles.module.css";
import { useEventMutations } from "../../hooks";

const ViewEvent = () => {
  const { id } = useParams<{ id: string }>();
  const [eventData, setEventData] = useState<EVENT>();
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [showMessage, setShowMessage] = useState("");
  const [eventWinnerLoading, setEventWinnerLoading] = useState(false);
  const { updateEventWinners } = useEventMutations();
  const history = useHistory();

  useEffect(() => {
    setLoading(true);
    const eventSubscribe = firebaseApp
      .firestore()
      .collection("events")
      .doc(id)
      .onSnapshot(
        (eventDoc) => {
          if (eventDoc.exists) {
            setEventData({
              id: eventDoc.id,
              ...eventDoc.data(),
            } as EVENT);
            setErrorMessage("");
          } else {
            setEventData(undefined);
            setErrorMessage("Event does not exists");
          }
          setLoading(false);
        },
        (error) => {
          setErrorMessage(error.message);
          setLoading(false);
        }
      );
    return () => eventSubscribe();
  }, [id]);

  if (loading) {
    return (
      <Grid container justifyContent="center">
        <CircularProgress />
      </Grid>
    );
  }

  const onWinners = async () => {
    try {
      setEventWinnerLoading(true);
      if (eventData) {
        await updateEventWinners(eventData);
        setShowMessage("Winners Updated");
      } else {
        throw new Error("Event does not exist");
      }
      setEventWinnerLoading(false);
    } catch (error) {
      setErrorMessage(error.message);
      setEventWinnerLoading(false);
    }
  };

  return (
    <>
      {errorMessage && (
        <Alert
          message={errorMessage}
          color="error"
          onClose={() => setErrorMessage("")}
        />
      )}
      {showMessage && (
        <Alert
          message={showMessage}
          color="info"
          onClose={() => setShowMessage("")}
        />
      )}
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        className={styles.topTitle}
      >
        <Typography variant="h6">View Event</Typography>
        <Button
          variant="text"
          color="primary"
          size="small"
          startIcon={<EditIcon />}
          onClick={() => history.push(Routes.EVENT + "/edit/" + id)}
        >
          Edit
        </Button>
      </Grid>
      <div>
        {eventData && (
          <EventForm
            data={eventData}
            formMode="view"
            showWinnerButton={eventData.hasWinners && !eventData.winners?.first}
            onWinnerButton={onWinners}
            eventWinnerLoading={eventWinnerLoading}
          />
        )}
      </div>
    </>
  );
};

export default ViewEvent;
