import {
  Button,
  CircularProgress,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Typography,
} from "@material-ui/core";
import { useCallback, useMemo, useState } from "react";
import Alert from "../../components/alert";
import firebaseApp from "../../firebase";
import styles from "./styles.module.css";

const Login = () => {
  const [mobile, setMobile] = useState("");
  const [otp, setOtp] = useState("");
  const [verifyOtpFunction, setVerifyOtpFunction] =
    useState<firebaseApp.auth.ConfirmationResult>();
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const recaptcha = useCallback(
    () => new firebaseApp.auth.RecaptchaVerifier("recaptcha-container"),
    []
  );

  const sendOtp = async () => {
    try {
      setLoading(true);
      const result = await firebaseApp
        .auth()
        .signInWithPhoneNumber("+91" + mobile, recaptcha());
      setVerifyOtpFunction(result);
      setLoading(false);
    } catch (error) {
      setErrorMessage(error.message);
      setLoading(false);
    }
  };

  const login = async () => {
    try {
      setLoading(true);
      await verifyOtpFunction?.confirm(otp);
      setLoading(false);
      resetInputFields();
    } catch (error) {
      setErrorMessage(error.message);
      setLoading(false);
    }
  };

  const resetInputFields = () => {
    setErrorMessage("");
    setLoading(false);
    setVerifyOtpFunction(undefined);
    recaptcha().clear();
    setMobile("");
    setOtp("");
  };

  const isOtpSent = useMemo(() => {
    return !!(typeof verifyOtpFunction !== "undefined");
  }, [verifyOtpFunction]);

  const buttonDisabled = useMemo(
    () =>
      !(
        (isOtpSent && otp.length === 6) ||
        (!isOtpSent && mobile.length === 10)
      ) || loading,
    [isOtpSent, loading, mobile.length, otp.length]
  );

  const handleMobileInput = (str: string) => {
    setMobile(str);
    if (isOtpSent && str.length < 10) {
      setVerifyOtpFunction(undefined);
      recaptcha().clear();
    }
  };

  return (
    <Grid
      container
      className={styles.container}
      justifyContent="center"
      xs={12}
    >
      {errorMessage && (
        <Alert
          message={errorMessage}
          color="error"
          onClose={() => setErrorMessage("")}
        />
      )}
      <form className={styles.form}>
        <Grid item container xs={12} justifyContent="center">
          <Typography variant="h4" className={styles.title}>
            Login
          </Typography>
        </Grid>
        <Grid container item xs={12}>
          <FormControl fullWidth variant="outlined" margin="dense">
            <InputLabel htmlFor="mobile-input">Mobile</InputLabel>
            <OutlinedInput
              id="mobile-input"
              value={mobile}
              onChange={(e) =>
                (new RegExp(/^[0-9]\d*$/).test(e.target.value) ||
                  e.target.value === "") &&
                e.target.value.length <= 10 &&
                handleMobileInput(e.target.value)
              }
              startAdornment={
                <InputAdornment position="start">+91</InputAdornment>
              }
              type="tel"
              labelWidth={50}
              disabled={loading}
            />
            {!isOtpSent && <div id="recaptcha-container" />}
          </FormControl>
        </Grid>
        {isOtpSent && (
          <Grid container item xs={12}>
            <FormControl fullWidth variant="outlined" margin="dense">
              <InputLabel htmlFor="otp-input">OTP</InputLabel>
              <OutlinedInput
                id="otp-input"
                value={otp}
                onChange={(e) =>
                  (new RegExp(/^[0-9]\d*$/).test(e.target.value) ||
                    e.target.value === "") &&
                  e.target.value.length <= 6 &&
                  setOtp(e.target.value)
                }
                type="tel"
                labelWidth={30}
                disabled={loading}
              />
            </FormControl>
          </Grid>
        )}
        <Grid container item xs={12} justifyContent="center">
          <Button
            variant="outlined"
            className={styles.button}
            color="primary"
            onClick={isOtpSent ? login : sendOtp}
            disabled={buttonDisabled}
          >
            {loading && (
              <CircularProgress size="1rem" className={styles.buttonLoading} />
            )}
            {isOtpSent ? "Login" : "Send OTP"}
          </Button>
        </Grid>
      </form>
    </Grid>
  );
};

export default Login;
