import {
  FormControl,
  FormHelperText,
  InputLabel,
  OutlinedInput,
  OutlinedInputProps,
} from "@material-ui/core";
import { FC } from "react";

type FormInputProps = OutlinedInputProps & {
  label: string;
  nameKey: string;
  errorMessage?: string;
  required?: boolean;
  errors?: string[];
  helperText?: string;
  disabledTextColor?: string | "unset";
};

const FormInput: FC<FormInputProps> = ({
  label,
  nameKey,
  errors,
  errorMessage,
  required = false,
  helperText,
  disabledTextColor = "black",
  ...otherProps
}) => {
  return (
    <FormControl
      variant="outlined"
      margin="dense"
      required={required}
      fullWidth
    >
      <InputLabel htmlFor={nameKey}>{label}</InputLabel>
      <OutlinedInput
        id={nameKey}
        labelWidth={(label.length + 2) * 8.5}
        style={
          disabledTextColor !== "unset" ? { color: disabledTextColor } : {}
        }
        {...otherProps}
      />
      {!!(errors?.includes(nameKey) && errorMessage) ? (
        <FormHelperText error>{errorMessage}</FormHelperText>
      ) : null}
      {!!helperText ? <FormHelperText>{helperText}</FormHelperText> : null}
    </FormControl>
  );
};

export default FormInput;
