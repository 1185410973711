import { Card, Chip, CircularProgress } from "@material-ui/core";
import { useState } from "react";
import DataTable, { IDataTableColumn } from "react-data-table-component";
import Alert from "../../components/alert";
import { HOST } from "@talsho/types";
import styles from "./styles.module.css";
import useGlobalState from "../../store/useGlobalState";

const Host = () => {
  const [errorMessage, setErrorMessage] = useState("");

  const {
    globalState: { hosts },
  } = useGlobalState();

  const columns: IDataTableColumn<HOST>[] = [
    {
      name: "Name",
      selector: "name",
    },
    {
      name: "Is Active",
      selector: (host) => (
        <Chip
          variant="outlined"
          size="small"
          label={host.isActive ? "Active" : "Inactive"}
          color={host.isActive ? "primary" : "secondary"}
        />
      ),
    },
    {
      name: "Host Users",
      selector: (host) => {
        return (
          <>
            <p>{host.id}</p>
          </>
        );
      },
    },
  ];
  return (
    <div>
      {errorMessage && (
        <Alert
          message={errorMessage}
          color="error"
          onClose={() => setErrorMessage("")}
        />
      )}
      <Card elevation={3}>
        <DataTable
          progressComponent={<CircularProgress className={styles.loader} />}
          data={hosts || []}
          title="Hosts"
          columns={columns}
          defaultSortField="name"
          pagination
          highlightOnHover
          // actions={actions}
        />
      </Card>
    </div>
  );
};

export default Host;
