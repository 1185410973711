import { EVENT, USER, USER_ROLE } from "@talsho/types";
import { Timestamp } from "@talsho/types/common";
import moment from "moment";
import { firebaseConfig } from "../config";
import firebaseApp from "../firebase";

export const checkIfUserHasAccess = ({ roles }: USER) =>
  roles.includes(USER_ROLE.ADMIN) || roles.includes(USER_ROLE.CREATOR);

export const getUserRole = ({ roles }: USER) =>
  roles.includes(USER_ROLE.ADMIN)
    ? USER_ROLE.ADMIN
    : roles.includes(USER_ROLE.CREATOR)
    ? USER_ROLE.CREATOR
    : USER_ROLE.USER;

export const getReadableTime = (
  time: firebaseApp.firestore.Timestamp,
  format: string = "DD MMM YYYY"
) => moment(time.toDate().toString()).format(format);

export const getBase64FromFile = async (file: File) =>
  new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = () => reject(new Error("Could not load the file."));
  });

export const getFirebaseTimestamp = (date: Date) =>
  firebaseApp.firestore.Timestamp.fromDate(date) as Timestamp;

export const eventDefaultState: EVENT = {
  id: "",
  hostId: "",
  name: "",
  description: "",
  eventPhase1: getFirebaseTimestamp(moment().toDate()),
  eventPhase2: getFirebaseTimestamp(moment().add(5, "d").toDate()),
  isEventLive: true,
  isFeatured: false,
  hasWinners: false,
  hasJudges: false,
  rules: [
    {
      description: "",
    },
  ],
  searchKeywords: [],
  enrollmentLimit: 0,
  enrollments: [],
  createdate: getFirebaseTimestamp(moment().toDate()),
  lastUpdate: getFirebaseTimestamp(moment().toDate()),
  poster: {
    src: "",
    title: "",
  },
  talent: "",
};

export const isProd = !(firebaseConfig.projectId === "talsho-dev");

export const searchKeywordsArray = (name: string): string[] =>
  [].concat.apply(
    [],
    name
      .replace(/[^\w\s]/gi, "")
      .split(" ")
      .filter((i: any) => i)
      .map((i: any) =>
        i
          .split("")
          .map((e: any, index: any) => i.substring(0, index + 1).toLowerCase())
      )
  );

export const eventTimeStatus = (event: EVENT) => {
  const { eventPhase1, eventPhase2 } = event;
  const diff1 = moment(eventPhase1.toDate()).diff(moment());
  const diff2 = moment(eventPhase2.toDate()).diff(moment());
  if (diff1 < 0) {
    return 1;
  } else if (diff1 > 0 && diff2 < 0) {
    return 0;
  } else {
    return -1;
  }
};
