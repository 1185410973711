const Routes = {
  HOME: "/home",
  USER: "/user",
  EVENT: "/event",
  VIEW_EVENT: "/event/:id",
  ADD_EVENT: "/event/add",
  EDIT_EVENT: "/event/edit/:id",
  TALENT: "/talent",
  LOGIN: "/login",
  HOST: "/host",
};

export default Routes;
