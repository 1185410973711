import { EVENT, POST, TOP_POST, WINNERS } from "@talsho/types";
import moment from "moment";
import firebaseApp, { gcsBucket } from "../../firebase";
import useGlobalState from "../../store/useGlobalState";
import { getFirebaseTimestamp, searchKeywordsArray } from "../../utils/common";

type UploadEventImage = (base64: string, eventId: string) => Promise<string>;

export const useEventMutations = () => {
  const {
    globalState: { currentHost },
  } = useGlobalState();

  const uploadEventImage: UploadEventImage = async (base64, eventId) => {
    try {
      if (!currentHost?.id) {
        throw new Error(
          "Host is not linked, please contact admin to link your account."
        );
      }
      const imageRef = gcsBucket.ref(`events/${eventId}/poster`);
      const result = await imageRef.putString(
        base64.split("base64,")[1],
        "base64",
        {
          contentType: "image/png",
        }
      );
      if (result.state === firebaseApp.storage.TaskState.SUCCESS) {
        firebaseApp.functions().httpsCallable("purgeCloudflareCacheByURLs")([
          `https://devmedia.talsho.com/events/${eventId}/poster`,
          `https://devmedia.talsho.com/events/${eventId}/poster/?key=${process.env.REACT_APP_TALSHO_MEDIA_ACCESS_KEY}`,
          `https://devmedia.talsho.com/events/${eventId}/poster?key=${process.env.REACT_APP_TALSHO_MEDIA_ACCESS_KEY}`,
        ]);
        return await imageRef.getDownloadURL();
      } else {
        throw new Error(
          "Unexpected Error, was not able to upload, please try again later."
        );
      }
    } catch (error) {
      throw new Error(error.message);
    }
  };

  const editEvent = async (event: EVENT) => {
    try {
      if (!currentHost?.id) {
        throw new Error(
          "Host is not linked, please contact admin to link your account."
        );
      }
      let eventData: EVENT = {
        ...event,
        searchKeywords: searchKeywordsArray(event.name),
        hostId: currentHost?.id,
      };
      if (eventData.hasWinners && !eventData.winners?.first) {
        eventData = {
          ...eventData,
          prize: [
            { position: "first", value: 1 },
            { position: "second", value: 2 },
            { position: "third", value: 3 },
          ],
        };
      }
      if (!eventData.hasWinners && eventData.winners?.first) {
        eventData = {
          ...eventData,
          winners: undefined,
        };
        const topPosts = await firebaseApp
          .firestore()
          .collection(`events/${event.id}/topPosts`)
          .get();
        await Promise.all(
          topPosts.docs.map(async (topPost) => {
            await firebaseApp
              .firestore()
              .doc(`events/${event.id}/topPosts/${topPost.id}`)
              .delete();
          })
        );
      }
      if (!eventData.judgeMobile) {
        eventData = {
          ...eventData,
          judgeMobile: undefined,
        };
      }
      return await firebaseApp
        .firestore()
        .collection("events")
        .doc(event.id)
        .update({
          ...eventData,
          winners: !eventData.winners
            ? firebaseApp.firestore.FieldValue.delete()
            : eventData.winners,
          judgeMobile: !eventData.judgeMobile
            ? firebaseApp.firestore.FieldValue.delete()
            : eventData.judgeMobile,
        });
    } catch (error) {
      throw error;
    }
  };

  const createEvent = async (event: EVENT) => {
    try {
      if (!currentHost?.id) {
        throw new Error(
          "Host is not linked, please contact admin to link your account."
        );
      }
      const eventData: EVENT = event.hasWinners
        ? {
            ...event,
            prize: [
              { position: "first", value: 1 },
              { position: "second", value: 2 },
              { position: "third", value: 3 },
            ],
          }
        : { ...event };
      await firebaseApp
        .firestore()
        .collection("events")
        .doc(event.id)
        .set({
          ...eventData,
          searchKeywords: searchKeywordsArray(event.name),
          hostId: currentHost?.id,
        });
      return;
    } catch (error) {
      throw error;
    }
  };

  const updateEventWinners = async (event: EVENT) => {
    try {
      if (event.winners?.first) {
        throw new Error(
          "Winner already updated, if you want to delete/re-update the winners please contact support@talsho.com"
        );
      } else {
        const postSnapshot = await firebaseApp
          .firestore()
          .collection("posts")
          .where("disablePost", "==", false)
          .where("eventId", "==", event.id)
          .orderBy("likeCount", "desc")
          .orderBy("viewCount", "asc")
          .orderBy("impressionCount", "asc")
          .limit(10)
          .get();
        const winnerDataList = {} as WINNERS;
        await Promise.all(
          postSnapshot.docs.map(async (post, index) => {
            const postData = { ...post.data(), id: post.id } as POST;
            const winnerData: TOP_POST = {
              userId: postData.userId,
              postId: post.id,
              eventId: event.id,
              likeCount: postData.likeCount,
              position: index + 1,
            };
            if (index + 1 <= 3) {
              switch (index + 1) {
                case 1:
                  winnerDataList.first = winnerData;
                  break;
                case 2:
                  winnerDataList.second = winnerData;
                  break;
                case 3:
                  winnerDataList.third = winnerData;
                  break;
                default:
                  break;
              }
            }
            if (postSnapshot.size === index + 1) {
              await firebaseApp
                .firestore()
                .collection("events")
                .doc(event.id)
                .update({
                  winners: winnerDataList,
                  lastUpdate: getFirebaseTimestamp(moment().toDate()),
                });
            }
            return await firebaseApp
              .firestore()
              .collection(`events/${event.id}/topPosts`)
              .add(winnerData);
          })
        );
      }
    } catch (error) {
      throw error;
    }
  };

  return {
    uploadEventImage,
    editEvent,
    createEvent,
    updateEventWinners,
  };
};
