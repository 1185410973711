import { Container } from "@material-ui/core";
import React from "react";
import Footer from "../components/footer";
import Header from "../components/header";
import styles from "./styles.module.css";

const Layout: React.FC = ({ children }) => {
  return (
    <Container className={styles.container}>
      <Header />
      <div className={styles.contentContainer}>{children}</div>
      <Footer />
    </Container>
  );
};

export default Layout;
