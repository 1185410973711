import {
  AppBar,
  IconButton,
  MenuItem,
  Toolbar,
  Typography,
  Menu,
} from "@material-ui/core";
import { USER_ROLE } from "@talsho/types";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { FC, MouseEvent, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { getUserRole } from "../../utils/common";
import Routes from "../../utils/routes";

import styles from "./styles.module.css";
import firebaseApp from "../../firebase";
import useGlobalState from "../../store/useGlobalState";

const AdminLinks = () => {
  const location = useLocation();
  const currentTab = location.pathname;
  return (
    <>
      <Link
        to={Routes.EVENT}
        className={`${styles.tab} ${
          currentTab === Routes.EVENT && styles.tabSelected
        }`}
      >
        Event
      </Link>
      <Link
        to={Routes.TALENT}
        className={`${styles.tab} ${
          currentTab === Routes.TALENT && styles.tabSelected
        }`}
      >
        Talent
      </Link>
      <Link
        to={Routes.USER}
        className={`${styles.tab} ${
          currentTab === Routes.USER && styles.tabSelected
        }`}
      >
        User
      </Link>
      <Link
        to={Routes.HOST}
        className={`${styles.tab} ${
          currentTab === Routes.HOST && styles.tabSelected
        }`}
      >
        Host
      </Link>
    </>
  );
};

const CreatorLinks = () => {
  const location = useLocation();
  const currentTab = location.pathname;
  return (
    <Link
      to={Routes.EVENT}
      className={`${styles.tab} ${
        currentTab === Routes.EVENT && styles.tabSelected
      }`}
    >
      Event
    </Link>
  );
};

const Header: FC = () => {
  const {
    globalState: { currentUser },
  } = useGlobalState();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [menuOpen, setMenuOpen] = useState(false);

  const handleOpenMenu = (event: MouseEvent<HTMLElement>) => {
    setMenuOpen(!menuOpen);
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setMenuOpen(false);
    setAnchorEl(null);
  };

  const handleLogout = () => {
    firebaseApp.auth().signOut();
    handleCloseMenu();
  };

  return (
    <AppBar className={styles.appBar}>
      <Toolbar className={styles.toolBar}>
        <Typography variant="h6">Talsho</Typography>
        {currentUser && (
          <div>
            {getUserRole(currentUser) === USER_ROLE.ADMIN ? (
              <AdminLinks />
            ) : getUserRole(currentUser) === USER_ROLE.CREATOR ? (
              <CreatorLinks />
            ) : null}
            <IconButton onClick={handleOpenMenu}>
              <MoreVertIcon className={styles.menuIcon} />
            </IconButton>
            <Menu anchorEl={anchorEl} open={menuOpen} onClose={handleCloseMenu}>
              <MenuItem onClick={handleLogout}>Logout</MenuItem>
            </Menu>
          </div>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default Header;
