import { HOST } from "@talsho/types";
import { TALENT, USER } from "@talsho/types";

export type GLOBAL_STATE = {
  talents?: TALENT[];
  currentUser?: USER;
  hosts?: HOST[];
  currentHost?: HOST;
};

export type GLOBAL_DISPATCH = {
  type: string;
  payload?: {
    [key: string]: any;
  };
  error?: boolean;
  meta?: any;
};

export type GLOBAL_CONTEXT = {
  globalState: GLOBAL_STATE;
  globalDispatch: (action: GLOBAL_DISPATCH) => void;
};

// Action Types
export const actionTypes = {
  UPDATE_TALENTS: "UPDATE_TALENTS",
  UPDATE_CURRENT_USER: "UPDATE_CURRENT_USER",
  UPDATE_HOSTS: "UPDATE_HOSTS",
  UPDATE_CURRENT_HOSTS: "UPDATE_CURRENT_HOSTS",
  RESET_STORE: "RESET_STORE",
};
