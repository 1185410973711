import { useEffect } from "react";
import { FC } from "react";
import { Redirect, Route, RouteProps, useHistory } from "react-router-dom";
import useGlobalState from "../../store/useGlobalState";

const PrivateRoute: FC<RouteProps> = (routeProps) => {
  const history = useHistory();
  const {
    globalState: { currentUser },
  } = useGlobalState();

  useEffect(() => {
    if (!currentUser) {
      history.push("/login");
    }
  }, [currentUser, history]);

  if (!currentUser) {
    <Redirect to="/login" />;
  }
  return <Route {...routeProps} />;
};

export default PrivateRoute;
