import { Grid, Typography } from "@material-ui/core";
import { useState } from "react";
import Alert from "../../../../components/alert";
import { eventDefaultState } from "../../../../utils/common";
import EventForm, { OnSaveEventProps } from "../../../../components/eventForm";
import styles from "./styles.module.css";
import { useEventMutations } from "../../hooks";
import firebaseApp from "../../../../firebase";
import { EVENT } from "@talsho/types";
import { useHistory } from "react-router-dom";
import Routes from "../../../../utils/routes";

const AddEvent = () => {
  const history = useHistory();
  const [createLoading, setCreateLoading] = useState(false);
  const { uploadEventImage, createEvent } = useEventMutations();
  const [errorMessage, setErrorMessage] = useState("");
  const onSubmit = async ({ event, updatedPoster }: OnSaveEventProps) => {
    try {
      setCreateLoading(true);
      const newEventId = firebaseApp.firestore().collection("events").doc().id;
      if (updatedPoster) {
        const posterUrl = await uploadEventImage(
          updatedPoster.base64,
          newEventId
        );
        const eventData = {
          ...event,
          id: newEventId,
          poster: { src: posterUrl, title: updatedPoster.fileName },
        } as EVENT;
        await createEvent(eventData);
        history.push(Routes.EVENT + "/" + eventData.id);
      } else {
        setErrorMessage("Please select a poster image for the event.");
      }
      setCreateLoading(false);
    } catch (error) {
      setErrorMessage(error.message);
      setCreateLoading(false);
    }
  };
  return (
    <>
      {errorMessage && (
        <Alert
          message={errorMessage}
          color="error"
          onClose={() => setErrorMessage("")}
        />
      )}
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        className={styles.topTitle}
      >
        <Typography variant="h6">Create Event</Typography>
      </Grid>
      <EventForm
        data={eventDefaultState}
        formMode="create"
        onSubmit={onSubmit}
        loading={createLoading}
      />
    </>
  );
};

export default AddEvent;
