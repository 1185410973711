import { useEffect, useState } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import PrivateRoute from "./components/privateRoute";
import PublicRoute from "./components/publicRoute";
import { CircularProgress, Grid } from "@material-ui/core";
import Layout from "./layout";
import firebaseApp from "./firebase";
import Routes from "./utils/routes";
import { HOST, TALENT, USER } from "@talsho/types";
import Home from "./pages/home";
import Login from "./pages/login";
import User from "./pages/user";
import Event from "./pages/event";
import Talent from "./pages/talent";
import Host from "./pages/host";
import { checkIfUserHasAccess } from "./utils/common";
import Alert from "./components/alert";
import ViewEvent from "./pages/event/containers/viewEvent";
import EditEvent from "./pages/event/containers/editEvent";
import AddEvent from "./pages/event/containers/addEvent";
import {
  resetGlobalStore,
  updateCurrentHost,
  updateCurrentUser,
  updateHosts,
  updateTalent,
} from "./store/actions";
import useGlobalState from "./store/useGlobalState";

const App = () => {
  const [initialLoading, setInitialLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");

  const { globalDispatch } = useGlobalState();

  useEffect(() => {
    let subscribeUser = () => {};
    let subscribeTalents = () => {};
    let subscribeHosts = () => {};
    const subscribeAuth = firebaseApp
      .auth()
      .onAuthStateChanged(async (authUser) => {
        if (authUser) {
          firebaseApp
            .firestore()
            .collection("hosts")
            .where("userIds", "array-contains", authUser.uid)
            .onSnapshot(
              (hostDocs) => {
                const host = hostDocs.docs.map(
                  (hostDoc) =>
                    ({
                      ...hostDoc.data(),
                      id: hostDoc.id,
                    } as HOST)
                );
                if (host[0]) {
                  globalDispatch(updateCurrentHost(host[0]));
                } else {
                  globalDispatch(updateCurrentHost(undefined));
                }
              },
              () => {
                globalDispatch(updateTalent([]));
              }
            );
          subscribeTalents = firebaseApp
            .firestore()
            .collection("talents")
            .onSnapshot(
              (talentDocs) => {
                const talents = talentDocs.docs.map(
                  (talentDoc) =>
                    ({
                      ...talentDoc.data(),
                      id: talentDoc.id,
                    } as TALENT)
                );
                globalDispatch(updateTalent(talents));
              },
              () => {
                globalDispatch(updateTalent([]));
              }
            );
          await new Promise((resolve) => {
            subscribeHosts = firebaseApp
              .firestore()
              .collection("hosts")
              .onSnapshot(
                (hostDocs) => {
                  const hosts = hostDocs.docs.map(
                    (host) => ({ ...host.data(), id: host.id } as HOST)
                  );
                  globalDispatch(updateHosts(hosts));
                  resolve(undefined);
                },
                () => {
                  globalDispatch(updateHosts([]));
                  resolve(undefined);
                }
              );
          });
          await new Promise((resolve) => {
            subscribeUser = firebaseApp
              .firestore()
              .collection("users")
              .doc(authUser.uid)
              .onSnapshot(
                (userDoc) => {
                  const user = {
                    id: authUser.uid,
                    ...userDoc.data(),
                  } as USER;
                  if (checkIfUserHasAccess(user)) {
                    globalDispatch(updateCurrentUser(user));
                  } else {
                    globalDispatch(resetGlobalStore());
                    firebaseApp.auth().signOut();
                    setErrorMessage("You do not have admin access.");
                  }
                  resolve(null);
                },
                (error) => {
                  setErrorMessage(error.message);
                  resolve(null);
                }
              );
          });
        } else {
          globalDispatch(resetGlobalStore());
        }
        setInitialLoading(false);
      });
    return () => {
      subscribeAuth();
      subscribeUser();
      subscribeTalents();
      subscribeHosts();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (initialLoading) {
    return (
      <Grid container justifyContent="center">
        <CircularProgress />
      </Grid>
    );
  }
  return (
    <Layout>
      {errorMessage && (
        <Alert
          message={errorMessage}
          color="error"
          onClose={() => setErrorMessage("")}
        />
      )}
      <Switch>
        <PrivateRoute path={Routes.HOME}>
          <Home />
        </PrivateRoute>
        <PrivateRoute path={Routes.USER}>
          <User />
        </PrivateRoute>
        <PrivateRoute path={Routes.ADD_EVENT}>
          <AddEvent />
        </PrivateRoute>
        <PrivateRoute path={Routes.EDIT_EVENT}>
          <EditEvent />
        </PrivateRoute>
        <PrivateRoute path={Routes.VIEW_EVENT}>
          <ViewEvent />
        </PrivateRoute>
        <PrivateRoute path={Routes.EVENT}>
          <Event />
        </PrivateRoute>
        <PrivateRoute path={Routes.TALENT}>
          <Talent />
        </PrivateRoute>
        <PrivateRoute path={Routes.HOST}>
          <Host />
        </PrivateRoute>
        <PublicRoute path={Routes.LOGIN}>
          <Login />
        </PublicRoute>
        <Route path="/">
          <Redirect to={Routes.LOGIN} />
        </Route>
      </Switch>
    </Layout>
  );
};

export default App;
