import { actionTypes, GLOBAL_STATE, GLOBAL_DISPATCH } from "./types";
import { initialState } from "./GlobalStateProvider";

const globalReducer = (
  state: GLOBAL_STATE,
  action: GLOBAL_DISPATCH
): GLOBAL_STATE => {
  switch (action.type) {
    case actionTypes.UPDATE_TALENTS:
      const talents = action.payload?.talents;
      return {
        ...state,
        talents,
      };
    case actionTypes.UPDATE_CURRENT_USER:
      const currentUser = action.payload?.currentUser;
      return {
        ...state,
        currentUser,
      };
    case actionTypes.UPDATE_HOSTS:
      const hosts = action.payload?.hosts;
      return {
        ...state,
        hosts,
      };
    case actionTypes.UPDATE_CURRENT_HOSTS:
      const currentHost = action.payload?.currentHost;
      return {
        ...state,
        currentHost,
      };
    case actionTypes.RESET_STORE:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export default globalReducer;
