import { FC, ReactNode, useReducer } from "react";
import Context from "./context";
import { GLOBAL_STATE } from "./types";
import globalReducer from "./globalReducer";

type GlobalStateProviderProps = {
  children: ReactNode;
};

export const initialState: GLOBAL_STATE = {
  talents: [],
  currentUser: undefined,
};

const GlobalStateProvider: FC<GlobalStateProviderProps> = (props) => {
  const { children } = props;
  const [globalState, globalDispatch] = useReducer(globalReducer, initialState);
  return (
    <Context.Provider value={{ globalState, globalDispatch }}>
      {children}
    </Context.Provider>
  );
};

export default GlobalStateProvider;
