import firebaseApp from "firebase/app";
import "firebase/analytics";

import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import "firebase/functions";

import { firebaseConfig } from "./config";

firebaseApp.initializeApp(firebaseConfig);

const bucket = `gs://${
  firebaseConfig.projectId === "talsho-prod"
    ? "media.talsho.com"
    : "devmedia.talsho.com"
}`;

export const gcsBucket = firebaseApp.app().storage(bucket);

export default firebaseApp;
