import {
  FormControl,
  FormHelperText,
  Grid,
  OutlinedInput,
  Paper,
  FormLabel,
  FormControlLabel,
  Switch,
  List,
  ListItem,
  ListItemText,
  InputAdornment,
  InputLabel,
  Select,
  IconButton,
  Button,
  CircularProgress,
} from "@material-ui/core";
import { EVENT, RULES } from "@talsho/types";
import moment from "moment";
import { useState, FC, useRef, FormEvent, useMemo, useCallback } from "react";
import FormInput from "../formInput";
import styles from "./styles.module.css";
import PublishIcon from "@material-ui/icons/Publish";
import {
  eventTimeStatus,
  getBase64FromFile,
  getFirebaseTimestamp,
} from "../../utils/common";
import useGlobalState from "../../store/useGlobalState";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import _ from "lodash";

export type UpdatedPosterType = {
  base64: string;
  fileName: string;
};

export type OnSaveEventProps = {
  event: EVENT;
  updatedPoster?: UpdatedPosterType;
};

type EventFormProps = {
  data: EVENT;
  formMode: "edit" | "view" | "create";
  onSubmit?: (args: OnSaveEventProps) => void;
  loading?: boolean;
  showWinnerButton?: boolean;
  onWinnerButton?: () => void;
  eventWinnerLoading?: boolean;
};

const EventForm: FC<EventFormProps> = ({
  data,
  formMode,
  onSubmit: onFormSubmit,
  loading,
  showWinnerButton = false,
  onWinnerButton,
  eventWinnerLoading,
}) => {
  const [event, setEvent] = useState<EVENT>(_.cloneDeep(data));
  const [updatedPoster, setUpdatedPoster] = useState<UpdatedPosterType>();
  const [errors] = useState<string[]>([]);
  const isEdit = formMode === "edit" || formMode === "create";
  const {
    globalState: { talents },
  } = useGlobalState();

  const posterImageRef = useRef<HTMLInputElement>(null);

  const onAddRule = () => {
    setEvent((event) => {
      event.rules.push({ description: "" });
      return { ...event };
    });
  };

  const onRemoveRule = (rule: RULES) => {
    setEvent((event) => {
      event.rules.splice(event.rules.indexOf(rule), 1);
      return { ...event };
    });
  };

  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onFormSubmit?.({ event, updatedPoster });
  };

  const onReset = useCallback(() => {
    setUpdatedPoster(undefined);
    setEvent(_.cloneDeep(data));
  }, [data]);

  const deepCompare = useMemo(
    () => _.isEqual(event, data) && !updatedPoster,
    [event, data, updatedPoster]
  );

  return (
    <form onSubmit={onSubmit}>
      <Paper elevation={3} className={styles.paperContainer}>
        <Grid container spacing={3} xs={12} className={styles.unsetMargin}>
          <Grid className={styles.unsetMargin} item xs={12} md={4}>
            <FormInput
              required
              label="Name"
              nameKey="name"
              errorMessage="Please enter a valid Name"
              errors={errors}
              defaultValue={event.name}
              value={event.name}
              onChange={(e) =>
                setEvent((event) => ({ ...event, name: e.target.value }))
              }
              disabled={!isEdit || loading}
            />
          </Grid>
          <Grid className={styles.unsetMargin} item xs={12} md={4}>
            <FormInput
              required
              label="Start Time"
              nameKey="eventPhase1"
              errorMessage="Please enter a valid Event Start Time"
              errors={errors}
              type="datetime-local"
              defaultValue={moment(
                event.eventPhase1.toDate().toString()
              ).format("YYYY-MM-DDTHH:mm")}
              value={moment(event.eventPhase1.toDate().toString()).format(
                "YYYY-MM-DDTHH:mm"
              )}
              onChange={(e) =>
                setEvent((event) => ({
                  ...event,
                  eventPhase1: getFirebaseTimestamp(
                    moment(e.target.value, "YYYY-MM-DDTHH:mm").toDate()
                  ) as any,
                }))
              }
              disabled={!isEdit || loading}
            />
          </Grid>
          <Grid className={styles.unsetMargin} item xs={12} md={4}>
            <FormInput
              required
              label="End Time"
              nameKey="eventPhase2"
              errorMessage="Please enter a valid Event Start Time"
              errors={errors}
              type="datetime-local"
              defaultValue={moment(
                event.eventPhase2.toDate().toString()
              ).format("YYYY-MM-DDTHH:mm")}
              value={moment(event.eventPhase2.toDate().toString()).format(
                "YYYY-MM-DDTHH:mm"
              )}
              onChange={(e) =>
                setEvent((event) => ({
                  ...event,
                  eventPhase2: getFirebaseTimestamp(
                    moment(e.target.value, "YYYY-MM-DDTHH:mm").toDate()
                  ) as any,
                }))
              }
              disabled={!isEdit || loading}
            />
          </Grid>
          <Grid
            className={styles.unsetMargin}
            item
            container
            xs={12}
            md={4}
            justifyContent="center"
          >
            <FormControl required>
              <FormLabel>Poster Image</FormLabel>
              <div
                className={styles.posterUploadContainer}
                style={{ cursor: !isEdit || loading ? "default" : "pointer" }}
                onClick={() => posterImageRef.current?.click()}
              >
                <input
                  ref={posterImageRef}
                  accept="image/*"
                  id="poster-image"
                  type="file"
                  className={styles.posterInput}
                  disabled={!isEdit || loading}
                  onChange={async (e) => {
                    e.preventDefault();
                    const imageFile = e.target.files?.[0];
                    if (imageFile) {
                      try {
                        const base64 = await getBase64FromFile(imageFile);
                        setUpdatedPoster({
                          base64,
                          fileName: imageFile.name.split(".")[0],
                        });
                      } catch (error) {
                        alert(error.message);
                      }
                    }
                  }}
                />
                <Grid
                  container
                  justifyContent="center"
                  alignItems="center"
                  direction="column"
                >
                  {updatedPoster || event.poster.src ? (
                    <img
                      src={updatedPoster?.base64 || event.poster.src}
                      alt="poster"
                      className={styles.posterImage}
                    />
                  ) : (
                    <>
                      <PublishIcon color="primary" />
                      <div>Upload</div>
                    </>
                  )}
                </Grid>
              </div>
            </FormControl>
          </Grid>
          <Grid className={styles.unsetMargin} item xs={12} md={8}>
            <FormInput
              required
              label="Description"
              nameKey="description"
              errorMessage="Please enter a valid Description"
              errors={errors}
              defaultValue={event.description}
              value={event.description}
              onChange={(e) =>
                setEvent((event) => ({ ...event, description: e.target.value }))
              }
              multiline={true}
              disabled={!isEdit || loading}
              minRows={6}
              maxRows={6}
            />
          </Grid>
          <Grid className={styles.unsetMargin} item xs={12} md={4}>
            <FormLabel required>
              Rules
              {!(!isEdit || loading) && (
                <IconButton onClick={onAddRule}>
                  <AddCircleOutlineIcon color="action" />
                </IconButton>
              )}
            </FormLabel>
            <List dense={true}>
              <Grid className={styles.unsetMargin} item xs={12}>
                {event.rules.map((rule, index) =>
                  isEdit ? (
                    <Grid container item xs={12}>
                      <Grid item xs={11} md={10}>
                        <FormControl
                          variant="outlined"
                          margin="dense"
                          required={true}
                          fullWidth
                        >
                          <OutlinedInput
                            className={styles.colorBlack}
                            value={rule.description}
                            onChange={(e) =>
                              setEvent((event) => {
                                event.rules[index].description = e.target.value;
                                return { ...event };
                              })
                            }
                            multiline
                            fullWidth
                            disabled={loading}
                          />
                        </FormControl>
                      </Grid>
                      <Grid
                        container
                        xs={1}
                        md={2}
                        justifyContent="center"
                        alignItems="center"
                      >
                        {index > 0 && !loading && (
                          <IconButton onClick={() => onRemoveRule(rule)}>
                            <RemoveCircleOutlineIcon color="action" />
                          </IconButton>
                        )}
                      </Grid>
                    </Grid>
                  ) : (
                    <ListItem>
                      <ListItemText primary={rule.description} />
                    </ListItem>
                  )
                )}
              </Grid>
            </List>
          </Grid>
          <Grid className={styles.unsetMargin} item xs={12} md={4}>
            <FormControl variant="outlined" margin="dense" required fullWidth>
              <InputLabel htmlFor="talent-select">Talent</InputLabel>
              <Select
                className={styles.colorBlack}
                native
                defaultValue={event.talent}
                value={event.talent}
                onChange={(e) =>
                  setEvent((event) => ({
                    ...event,
                    talent: e.target.value as string,
                  }))
                }
                disabled={!isEdit || loading}
                inputProps={{
                  name: "talent",
                  id: "talent-select",
                }}
                labelWidth={60}
              >
                <option aria-label="None" value="" />
                {talents?.map((talent) => (
                  <option value={talent.id}>{talent.name}</option>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid className={styles.unsetMargin} item xs={12} md={4}>
            <FormInput
              required
              label="Enrolement Limit"
              nameKey="enrollmentLimit"
              errors={errors}
              type="number"
              defaultValue={0}
              onChange={(e) =>
                (new RegExp(/^[0-9]\d*$/).test(e.target.value) ||
                  e.target.value === "") &&
                setEvent((event) => ({
                  ...event,
                  enrollmentLimit: parseInt(e.target.value, 10),
                }))
              }
              value={event.enrollmentLimit}
              disabled={!isEdit || loading}
              helperText="Leave it as 0 for infinite Enrolement limit"
            />
          </Grid>
          <Grid container className={styles.unsetMargin} item xs={12} md={4}>
            <FormControl required>
              <FormLabel>Is Event Live</FormLabel>
              <FormControlLabel
                control={
                  <Switch
                    disabled={!isEdit || loading}
                    color="primary"
                    checked={event.isEventLive}
                    onChange={(e) =>
                      setEvent((event) => ({
                        ...event,
                        isEventLive: !event.isEventLive,
                      }))
                    }
                  />
                }
                label={event.isEventLive ? "Enabled" : "Disabled"}
              />
              <FormHelperText>
                Turn OFF if you want to disable the event
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid className={styles.unsetMargin} container item xs={12} md={4}>
            <FormControl required>
              <FormLabel>Has Winners</FormLabel>
              <FormControlLabel
                control={
                  <Switch
                    disabled={!isEdit || loading}
                    color="primary"
                    checked={event.hasWinners}
                    onChange={(e) =>
                      setEvent((event) => ({
                        ...event,
                        hasWinners: !event.hasWinners,
                      }))
                    }
                  />
                }
                label={event.hasWinners ? "Enabled" : "Disabled"}
              />
              <FormHelperText>
                Turn ON if you want to have Winners for the event
              </FormHelperText>
            </FormControl>
            {showWinnerButton && eventTimeStatus(event) === 1 && (
              <Grid
                className={styles.unsetMargin}
                item
                xs={12}
                justifyContent="center"
              >
                <Button
                  variant="outlined"
                  color="primary"
                  className={styles.formButtons}
                  disabled={eventWinnerLoading}
                  onClick={onWinnerButton}
                >
                  Update Winners
                  {eventWinnerLoading && (
                    <CircularProgress size={15} className={styles.loader} />
                  )}
                </Button>
              </Grid>
            )}
          </Grid>
          <Grid className={styles.unsetMargin} container item md={4} xs={12}>
            <FormControl required>
              <FormLabel>Has Judges</FormLabel>
              <FormControlLabel
                control={
                  <Switch
                    disabled={!isEdit || loading}
                    color="primary"
                    checked={event.hasJudges}
                    onChange={(e) =>
                      setEvent((event) => ({
                        ...event,
                        hasJudges: !event.hasJudges,
                        ...(event.hasJudges ? { judgeMobile: undefined } : {}),
                      }))
                    }
                  />
                }
                label={event.hasJudges ? "Enabled" : "Disabled"}
              />
              <FormHelperText>
                Turn ON if you want to have Judges for the event
              </FormHelperText>
            </FormControl>
            {event.hasJudges && (
              <Grid className={styles.unsetMargin} item xs={12}>
                <FormInput
                  required
                  label="Judge Mobile"
                  nameKey="judgeMobile"
                  errorMessage="Please enter a valid mobile"
                  helperText="Enter the judge's mobile number"
                  errors={errors}
                  defaultValue={event.judgeMobile}
                  value={event.judgeMobile || ""}
                  onChange={(e) =>
                    (new RegExp(/^[0-9]\d*$/).test(e.target.value) ||
                      e.target.value === "") &&
                    e.target.value.length <= 10 &&
                    setEvent((event) => ({
                      ...event,
                      judgeMobile: e.target.value,
                    }))
                  }
                  startAdornment={
                    <InputAdornment position="start">+91</InputAdornment>
                  }
                  type="tel"
                  disabled={!isEdit || loading}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
      </Paper>
      {formMode !== "view" && (
        <Grid
          container
          xs={12}
          className={styles.unsetMargin}
          justifyContent="center"
        >
          <Button
            variant="outlined"
            color="primary"
            className={styles.formButtons}
            disabled={loading || deepCompare}
            onClick={onReset}
          >
            Reset
          </Button>
          <Button
            variant="outlined"
            color="primary"
            className={styles.formButtons}
            type="submit"
            disabled={loading || deepCompare}
          >
            Submit
            {loading && (
              <CircularProgress size={15} className={styles.loader} />
            )}
          </Button>
        </Grid>
      )}
    </form>
  );
};

export default EventForm;
