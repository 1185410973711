import { HOST, TALENT, USER } from "@talsho/types";
import { GLOBAL_DISPATCH, actionTypes } from "./types";

export const updateTalent = (talents: TALENT[]): GLOBAL_DISPATCH => {
  return {
    type: actionTypes.UPDATE_TALENTS,
    payload: {
      talents,
    },
  };
};

export const updateCurrentUser = (currentUser?: USER): GLOBAL_DISPATCH => {
  return {
    type: actionTypes.UPDATE_CURRENT_USER,
    payload: {
      currentUser,
    },
  };
};

export const updateHosts = (hosts: HOST[]): GLOBAL_DISPATCH => {
  return {
    type: actionTypes.UPDATE_HOSTS,
    payload: {
      hosts,
    },
  };
};

export const updateCurrentHost = (currentHost?: HOST): GLOBAL_DISPATCH => {
  return {
    type: actionTypes.UPDATE_CURRENT_HOSTS,
    payload: {
      currentHost,
    },
  };
};

export const resetGlobalStore = (): GLOBAL_DISPATCH => {
  return {
    type: actionTypes.RESET_STORE,
  };
};
