import {
  Button,
  Card,
  CircularProgress,
  Grid,
  IconButton,
  InputBase,
} from "@material-ui/core";
import Add from "@material-ui/icons/Add";
import VisibilityIcon from "@material-ui/icons/Visibility";
import EditIcon from "@material-ui/icons/Edit";
import { EVENT, USER_ROLE } from "@talsho/types";
import { useEffect } from "react";
import { useState } from "react";
import { FC } from "react";
import DataTable, { IDataTableColumn } from "react-data-table-component";
import Alert from "../../components/alert";
import moment from "moment";
import styles from "./styles.module.css";
import { useHistory } from "react-router-dom";
import Routes from "../../utils/routes";
import SearchIcon from "@material-ui/icons/Search";
import useGlobalState from "../../store/useGlobalState";
import { getUserRole } from "../../utils/common";
import firebaseApp from "../../firebase";
import { resetGlobalStore } from "../../store/actions";

const Event: FC = () => {
  const history = useHistory();
  const [events, setEvents] = useState<EVENT[]>([]);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [searchTerm, setSearchTerm] = useState("");

  const {
    globalState: { currentUser, currentHost },
    globalDispatch,
  } = useGlobalState();

  useEffect(() => {
    let subscribeEvents = () => {};
    if (currentUser) {
      setLoading(true);
      const eventsCollectionRef =
        getUserRole(currentUser) === USER_ROLE.ADMIN
          ? firebaseApp.firestore().collection("events")
          : firebaseApp
              .firestore()
              .collection("events")
              .where("hostId", "==", currentHost?.id);
      try {
        subscribeEvents = eventsCollectionRef.onSnapshot(
          (eventsCollection) => {
            setLoading(false);
            setEvents(
              eventsCollection.docs.map(
                (eventDoc) =>
                  ({
                    id: eventDoc.id,
                    ...eventDoc.data(),
                  } as EVENT)
              )
            );
          },
          (error) => {
            setErrorMessage(error.message);
            setLoading(false);
          }
        );
      } catch (error) {
        setLoading(false);
        setErrorMessage(error.message);
      }
    } else {
      globalDispatch(resetGlobalStore());
      setErrorMessage("User not found, please login again");
    }
    return subscribeEvents;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentHost?.id, currentUser]);
  const columns: IDataTableColumn<EVENT>[] = [
    {
      name: "Name",
      selector: "name",
      sortable: true,
    },
    {
      name: "Poster",
      selector: (data) => (
        <>
          <img src={data.poster.src} alt="poster" className={styles.poster} />
        </>
      ),
      center: true,
    },
    {
      name: "Event Time",
      selector: (data) => (
        <>{`${moment(data.eventPhase1.toDate().toString()).format(
          "DD MMM YYYY"
        )} - ${moment(data.eventPhase2.toDate().toString()).format(
          "DD MMM YYYY"
        )}`}</>
      ),
      center: true,
    },
    {
      name: "Users Enrolled",
      selector: (data) => (
        <>
          {data.enrollments.length} /{" "}
          {data.enrollmentLimit === 0 ? "∞" : data.enrollmentLimit}
        </>
      ),
      center: true,
    },
    {
      name: "Created On",
      selector: (data) => (
        <>{moment(data.createdate.toDate().toString()).format("DD MMM YYYY")}</>
      ),
      center: true,
    },
    {
      name: "View",
      selector: (data) => (
        <IconButton
          color="primary"
          onClick={() => history.push("/event/" + data.id)}
        >
          <VisibilityIcon />
        </IconButton>
      ),
      center: true,
      width: "8%",
    },
    {
      name: "Edit",
      selector: (data) => (
        <IconButton
          color="primary"
          onClick={() => history.push("/event/edit/" + data.id)}
        >
          <EditIcon />
        </IconButton>
      ),
      center: true,
      width: "8%",
    },
  ];
  const actions = (
    <>
      <Grid container justifyContent="flex-end" alignItems="center">
        <div className={styles.searchContainer}>
          <SearchIcon color="primary" />
          <InputBase
            onChange={(e) => setSearchTerm(e.target.value)}
            color="primary"
            placeholder="Search…"
            inputProps={{ "aria-label": "search" }}
            className={styles.searchInput}
          />
        </div>
        <Button
          color="primary"
          endIcon={<Add />}
          onClick={() => history.push(Routes.EVENT + "/add")}
        >
          Create Event
        </Button>
      </Grid>
    </>
  );
  return (
    <div>
      {errorMessage && (
        <Alert
          message={errorMessage}
          color="error"
          onClose={() => setErrorMessage("")}
        />
      )}
      <Card elevation={3}>
        <DataTable
          progressPending={loading}
          progressComponent={<CircularProgress className={styles.loader} />}
          data={events.filter((event) =>
            event.name.toLowerCase().includes(searchTerm.toLowerCase())
          )}
          title="Events"
          columns={columns}
          defaultSortField="name"
          pagination
          highlightOnHover
          actions={actions}
        />
      </Card>
    </div>
  );
};

export default Event;
