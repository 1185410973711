import { useEffect } from "react";
import { FC } from "react";
import { Redirect, Route, RouteProps, useHistory } from "react-router-dom";
import useGlobalState from "../../store/useGlobalState";
import Routes from "../../utils/routes";

const PublicRoute: FC<RouteProps> = (routeProps) => {
  const { globalState } = useGlobalState();

  const history = useHistory();
  useEffect(() => {
    if (globalState.currentUser) {
      history.push(Routes.HOME);
    }
  }, [globalState.currentUser, history]);

  if (globalState.currentUser) {
    <Redirect to={Routes.HOME} />;
  }
  return <Route {...routeProps} />;
};

export default PublicRoute;
