import { Snackbar, SnackbarProps } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import { FC } from "react";

type AlertProps = SnackbarProps & {
  message: string;
  color?: "success" | "info" | "warning" | "error";
  onClose?: () => void;
};

const Alert: FC<AlertProps> = ({ message, color, onClose }) => {
  if (!message) {
    return null;
  }
  return (
    <Snackbar
      open={true}
      autoHideDuration={6000}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
    >
      <MuiAlert
        elevation={6}
        variant="filled"
        severity={color}
        onClose={onClose}
      >
        {message}
      </MuiAlert>
    </Snackbar>
  );
};

export default Alert;
